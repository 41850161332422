<template>
  <div class="warrty">
    <div class="tableList">
      <div class="exportData">
        <el-button class="textBtn" @click="AddRework" type="text">+新增</el-button>
        <el-button class="textBtn" @click="alldelete" type="text">- 批量删除</el-button>
      </div>
      <div class="addNameTitle">
        <span>用户账号: {{newSubModelData.userAccount}}</span>
        <span>用户昵称: {{newSubModelData.username}}</span>
        <span>部门: {{newSubModelData.department}}</span>
      </div>
      <div class="table">
        <el-table :data="tableData" @selection-change="handleSelectionChange" stripe style="width: 100%">
          <el-table-column  type="selection" width="55"></el-table-column>
          <el-table-column prop="code" label="供应商编码" width="150px" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="name" label="供应商名称" width="" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column label="操作" width="90px">
            <template #default="scope">
              <el-button class="textBtn" @click="eliminateDinlog(scope.$index, scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <dialog-box ref="prodiaData" componentName="SupchoiceDialog" dialogWidth="650px" :dialogShow="ProdialogShow" @handleClose='handleClose' title="供应商选择"
    :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addProdia', type:'primary', size:'mini'}]" @addProdia="prodiaBtn"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  name: 'AllocationDialog',
  props: ['newSubModelData'],
  components: Component.components,
  data: function () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      AddReworkNum: 1,
      ProdialogShow: false,
      categoryDataCopy: {},
      warrCheckData: [],
      tableData: []
    }
  },
  mounted () {
    this.tableData = this.newSubModelData.list || []
  },
  created () {

  },
  methods: {
    // 新增供应商弹框
    AddRework () {
      this.AddReworkNum = 2
      this.ProdialogShow = true
    },
    // 点击新增供应商弹框里的确认按钮
    prodiaBtn () {
      var categoryData = this.$refs.prodiaData.$refs.content.supChangeData
      if (categoryData) {
        var newTableData = []
        for (var item of categoryData) {
          newTableData.push(item)
        }
        if (this.tableData) {
          var arr = []
          newTableData.forEach(item => {
            arr.push({
              code: item.erpCode,
              name: item.name
            })
          })
          this.tableData.unshift(...arr)
        } else {
          this.tableData = arr
        }
        this.ProdialogShow = false
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择要添加的供应商'
        })
      }
    },

    // prodiaBtn () {
    //   this.categoryData = this.$refs.prodiaData.$refs.content.supChangeData
    //   if (this.categoryData.erpCode) {
    //     this.categoryDataCopy.code = this.categoryData.erpCode
    //     this.categoryDataCopy.name = this.categoryData.name
    //     if (this.AddReworkNum === 2) {
    //       this.newTableData.push({
    //         code: this.categoryData.erpCode,
    //         name: this.categoryData.name
    //       })
    //       console.log(' this.newTableData', this.newTableData)
    //       if (this.tableData) {
    //         this.tableData.unshift(...this.newTableData)
    //       } else {
    //         this.tableData = this.newTableData
    //       }
    //     }
    //     this.AddReworkNum = 1
    //     this.ProdialogShow = false
    //     this.$parent.$parent.getDataList()
    //   } else {
    //     this.$message({
    //       showClose: true,
    //       type: 'warning',
    //       message: '请点击选择供应商'
    //     })
    //   }
    // },

    // 获取参评品类定义选中的数据
    handleSelectionChange (val) {
      this.warrCheckData = val
      console.log('val', val)
    },

    // 删除
    handleRemove (index, row) {
      this.tableData.splice(index, 1)
    },
    // 删除弹框
    eliminateDinlog (index, row) {
      this.$confirm('删除后请点击确认进行保存', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleRemove(index, 1)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    alldelete () { // 批量删除
      if (this.warrCheckData.length === 0) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择要删除的数据'
        })
      } else {
        this.$confirm('删除后请点击确认进行保存', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var that = this
          // 如果选中数据存在
          var val = this.warrCheckData
          if (val) {
            val.forEach(function (item, index) { // 将选中数据遍历
              that.tableData.forEach(function (itemI, indexI) { // 遍历源数据
                // 如果选中数据跟源数据某一条标识相等，删除对应的源数据
                if (item.code === itemI.code) {
                  that.tableData.splice(indexI, 1)
                }
              })
            })
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      }

      // this.$confirm('删除后请点击确认进行保存', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {

      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   })
      // })
    },

    // 关闭弹窗
    handleClose () {
      this.ProdialogShow = false
    }
  }
}

</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.warrty{
  .tableList{
    padding: 0;
    :deep(.table .el-table td){
      padding: 2px 0;
    }
  }
}
.contractMenu{
 position: relative;
 .derivebtn{
  position: absolute;
  right: 35px;
  top: 8px;
  font-size: 16px;
 }
}
.addNameTitle{
  margin-bottom: 15px;
  text-align: right;
  span{
    padding-right: 50px;
  }
}
</style>
